import React, { useState, useRef, useEffect } from "react";
import { ClickAwayListener } from "@mui/material";
import {
  QuestionCircleOutlined,
  DownOutlined,
  RightOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import "../../../../assets/styles/HelpAndSupport.css";
import { HELP_MENUS } from "pages/common/Constants";
import { requestAPI } from "pages/common/Utils";

export default function HelpAndSupport() {
  const anchorRef = useRef(null);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [dropdownStates, setDropdownStates] = useState({});

  useEffect(() => {
    const getData = async () => {
      if (data.length === 0) {
        const resp = await requestAPI(HELP_MENUS, "GET", "", "");
        setData(resp.data);
      }
    };
    getData();
  }, [data]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
    setDropdownStates({});
  };

  const handleSelectDropdown = (key) => {
    setDropdownStates((prevStates) => ({
      ...prevStates,
      [key]: {
        ...prevStates[key],
        open: !prevStates[key]?.open,
        selectedChildren: prevStates[key]?.selectedChildren || [],
      },
    }));
  };

  const handleSelectChild = (dropdownKey, childKey) => {
    setDropdownStates((prevStates) => {
      const dropdown = prevStates[dropdownKey];
      const selectedChildren = dropdown?.selectedChildren || [];

      // Check if childKey is already selected
      const isSelected = selectedChildren.includes(childKey);

      // Update selectedChildren array
      return {
        ...prevStates,
        [dropdownKey]: {
          ...dropdown,
          selectedChildren: isSelected
            ? selectedChildren.filter((key) => key !== childKey) // Deselect
            : [...selectedChildren, childKey], // Select
        },
      };
    });
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className="help-and-support">
        <div ref={anchorRef} className="help-button" onClick={handleToggle}>
          <QuestionCircleOutlined className="icon-size" />
          <span>Help</span>
        </div>

        {open && (
          <div className="popup">
            {data.map((menu, key) => (
              <div key={menu.menuId}>
                <div
                  className={`dropdown-header ${
                    dropdownStates[key]?.open ? "active" : ""
                  }`}
                  onClick={() => handleSelectDropdown(key)}
                >
                  {dropdownStates[key]?.open ? (
                    <DownOutlined className="dropdown-icon" />
                  ) : (
                    <RightOutlined className="dropdown-icon" />
                  )}
                  {menu.menuName}
                </div>

                {dropdownStates[key]?.open && (
                  <div className="dropdown-menu">
                    {menu.subMenus.map((subMenu) => (
                      <div key={subMenu.subMenuId}>
                        <div
                          className={`dropdown-item ${
                            dropdownStates[key]?.selectedChildren.includes(
                              subMenu.subMenuId
                            )
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            handleSelectChild(key, subMenu.subMenuId)
                          }
                        >
                          <span>{subMenu.subMenuName}</span>
                          {dropdownStates[key]?.selectedChildren.includes(
                            subMenu.subMenuId
                          ) ? (
                            <MinusOutlined className="dropdown-icon" />
                          ) : (
                            <PlusOutlined className="dropdown-icon" />
                          )}
                        </div>

                        {dropdownStates[key]?.selectedChildren.includes(
                          subMenu.subMenuId
                        ) && (
                          <div className="dropdown-details">
                            {subMenu.content &&
                              subMenu.content.map((childSubMenu) => (
                                <div key={childSubMenu.contentId}>
                                  {/* Render content based on its type */}
                                  <h4>
                                    {childSubMenu.contentName}
                                  </h4>
                                  {childSubMenu.type === "IMAGE" && (
                                    <img
                                      src={childSubMenu.value}
                                      alt={childSubMenu.contentName}
                                      style={{
                                        maxWidth: "100%",
                                        height: "140px",
                                        objectFit: "contain", // Ensures the image is fully visible inside its container without being cropped
                                      }}
                                    />
                                  )}
                                  {childSubMenu.type === "TEXT" && (
                                    <div className="dropdown-answer">
                                      {childSubMenu.value}
                                    </div>
                                  )}
                                  {childSubMenu.type === "VIDEO" && (
                                    <div className="dropdown-answer">
                                      <video width="260" height="160" controls>
                                        <source
                                          src={childSubMenu.value}
                                          type="video/mp4"
                                          style={{
                                            maxWidth: "100%",
                                            height: "160px",
                                            objectFit: "contain",
                                          }}
                                        />
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    </div>
                                  )}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}
