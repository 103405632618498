import { createSlice } from '@reduxjs/toolkit';
import { requestAPI, getClientId } from 'pages/common/Utils';
import { GET_LOCATION_DETAILS, GET_DOC_BUCKET_PIVOT_DATA, DOWNLOAD_SKU_LIST_DB3 } from "pages/common/Constants";

/**
 * ⭐ Initial state for the inventory document slice.
 * 
 * @typedef {Object} InventoryDocState
 * @property {string} location - Current selected location.
 * @property {Array} locationData - Array of location data.
 * @property {Array} skuList - Array of SKU data.
 * @property {Array} tableData3 - Array of table data.
 * @property {boolean} loading - Indicates if data is loading.
 * @property {boolean} loading2 - Additional loading indicator.
 * @property {boolean} loading3 - Additional loading indicator for table data.
 * @property {string} downloadLink - Link for downloading data.
 */

const initialState = {
    location: '',
    locationData: [],
    skuList: [],
    tableData3: [],
    loading: true,
    loading2: true,
    loading3: true,
    downloadLink: '',
};


/**
 * ⭐ Slice for managing inventory document state and actions.
 * 
 * @type {import('@reduxjs/toolkit').Slice}
 */
const inventoryDocSlice = createSlice({
    name: 'inventoryDoc',
    initialState,
    reducers: {
        setLocationData(state, action) {
            state.locationData = action.payload;
        },
        setSkuList(state, action) {
            state.skuList = action.payload;
        },
        setTableData3(state, action) {
            state.tableData3 = action.payload;
        },
        setLocation(state, action) {
            state.location = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setLoading3(state, action) {
            state.loading3 = action.payload;
        },
        setDownloadLink(state, action) {
            state.downloadLink = action.payload;
        },
    },
});

export const {
    setLocationData,
    setSkuList,
    setTableData3,
    setLocation,
    setLoading,
    setLoading3,
    setDownloadLink,
} = inventoryDocSlice.actions;

export default inventoryDocSlice.reducer;

/**
 * 📅 Fetches location data from the API and sets it in the state.
 * 
 * @return {Function} Dispatches actions to fetch and set location data.
 */
export const fetchLocationData = (locationDataActive) => async (dispatch) => {
    try {
        // Make an API request to fetch sku details
        const locationValue = locationDataActive.find((x) => x.attribute === "All")?.attribute || locationDataActive[0]?.attribute ;
        dispatch(setLocationData([...locationDataActive]));
        dispatch(setLocation(locationValue));
        
    } catch (error) {
        console.error('Error fetching location data:', error);
    }
};


/**
 * 📦 Fetches SKU list from the API and sets it in the state.
 * 
 * @return {Function} Dispatches actions to fetch and set SKU list.
 */

export const fetchSkuList = () => async (dispatch) => {
    const customerId = getClientId();
    try {
        const response = await requestAPI(DOWNLOAD_SKU_LIST_DB3 + '?cid=' + customerId + '&loc=All', 'GET', '', '');
        dispatch(setSkuList(response.data));
    } catch (error) {
        console.error('Error fetching SKU list:', error);
    }
};


/**
 * 🔄 Fetches table data based on the current location and sets it in the state.
 * 
 * @param {string} locationValue - The location for which table data is fetched.
 * @return {Function} Dispatches actions to fetch and set table data.
 */
export const fetchTableData = (locationValue) => async (dispatch) => {
    const customerId = getClientId();
    dispatch(setLoading3(true));
    try {
        const response = await requestAPI(
            GET_DOC_BUCKET_PIVOT_DATA + '?cid=' + customerId + '&loc=' + locationValue + '&req=NOOPENPO',
            'GET',
            '',
            ''
        )
        dispatch(setTableData3(response.data));
        dispatch(setLoading3(false));
    } catch (error) {
        console.error('Error fetching table data:', error);
    }
};
